import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // router.jsのインポート
import 'destyle.css/destyle.css';

const app = createApp(App);

// Vue 3では、プラグインやルーターのような追加の機能を、createAppインスタンスに対して使用します。
app.use(router);

app.mount('#app');


