import { createRouter, createWebHashHistory } from 'vue-router';

// 各ページのコンポーネントをインポート
import AboutPage from '@/views/AboutPage.vue';
import ProjectPage from '@/views/ProjectPage.vue';
import ProfilePage from '@/views/ProfilePage.vue';

const routes = [
  {
    path: '/about',
    name: 'about',
    component: AboutPage,
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectPage,
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfilePage,
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
