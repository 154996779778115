<template>
  <div id="app">
    <LoadingComponent />
    <MouseStalker />
    <GlobalMenu />
    <router-view v-slot="{ Component }">
      <transition
        name="slide-up"
        mode="out-in"
      >
        <component
          :is="Component"
          :key="$route.fullPath"
          class="full-screen-page"
        />
      </transition>
    </router-view>
  </div>
</template>

<script>
  import LoadingComponent from './components/LoadingComponent.vue';
  import MouseStalker from './components/MouseStalker.vue';
  import GlobalMenu from './components/GlobalMenu.vue';

  export default {
    components: {
      LoadingComponent,
      MouseStalker,
      GlobalMenu
    }
  };
</script>

<style>
  html {
    background-color: black;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Roboto, sans-serif;
  }

  #app {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    background-color: hsl(0, 0%, 0%);
    color: white;
  }

  .pageContent {
    position: absolute;
    border-radius: 24px;
    bottom: 0;
    padding: 80px;
    height: calc(100vh - 80px);
    width: 100vw;
    background-color: blue;
    z-index: 9997;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  }

  h1 {
    font-size: 2rem;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  body {
    scrollbar-width: none;
  }

  ::selection {
    background-color: white;
    mix-blend-mode: difference;
  }


  .slide-up-enter-active, .slide-up-leave-active, .slide-up-leave-active {
    transition: transform .3s cubic-bezier(0.4, 0.0, 0.2, 1);
    transition-delay: .1s;
  }

  .slide-up-enter-from, .slide-up-leave-to {
    transform: translateY(100%);
  }

  .slide-up-enter-to, .slide-up-leave-from {
    transform: translateY(0);
  }

  .backButton{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: blue;
  }

  .backButton a{
    display: inline-block;
    padding: 16px 40px;
    background-color: yellow;
    color: black;
  }
</style>
