<template>
  <div class="wrapper">
    <div
      v-for="item in menuItems"
      :key="item.path"
      class="card"
    >
      <div class="cardHeader">
        <p class="cardSubtitle">
          {{ item.cardSubtitle }}
        </p>
        <p class="cardTitle">
          {{ item.cardTitle }}
        </p>
      </div>
      <div class="cardFooter">
        <router-link
          :to="item.path"
          class="cardButton"
        >
          {{ item.cardButton }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuItems: [
      { path: "/about", cardSubtitle:"Creative", cardTitle:"tnyu", cardButton: "Open"},
      { path: "/projects", cardSubtitle:"IP", cardTitle:"Cheetah-Kun", cardButton: "Open"},
      { path: "/about", cardSubtitle:"Food", cardTitle:"Craft Cola Lab", cardButton: "Open"},
      { path: "/about", cardSubtitle:"Food", cardTitle:"Craft Hamburg", cardButton: "Open"},
      { path: "/profile", cardSubtitle:"Profile", cardTitle:"Profile", cardButton: "Open"},
      ],
    };
  },
  watch: {
    $route(to) { 
      const wrapper = document.querySelector('.wrapper');
      if (!wrapper) return;

      if (this.menuItems.some(item => item.path === to.path)) {
        wrapper.style.transform = 'scale(0.95) translateY(32px)';
        wrapper.style.filter = 'blur(0px)';
        wrapper.style.opacity = '1';
      } else {
        wrapper.style.transform = 'scale(1)';
        wrapper.style.filter = 'blur(0px)';
        wrapper.style.opacity = '1';
      }
    }
  }
};
</script>

<style>
  .wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    position: relative;
    padding: 120px 16px;
    border-radius: 24px;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    filter: blur(0px);
    transform: scale(1.02);
    transition: transform 0.5s cubic-bezier(0.4, 0.0, 0.2, 1), filter 0.2s cubic-bezier(0.4, 0.0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0.0, 0.2, 1);
    background-color: #ff0000;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    padding: 8px;
    width: 100%;
    background-color: orangered;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  }

  .cardHeader{
    padding: 8px;
  }

  .cardBody{
    padding: 8px;
  }

  .cardFooter{
    margin: 8px 0;
    padding: 8px;
  }

  .cardSubtitle {
    margin: 0px 0px 4px;
    padding: 0px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  .cardTitle {
    margin: 0px;
    padding: 0px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
  }

  .cardButton{
    font-size: 17px;
    font-weight: normal;
    color: yellow;
    transition: all .2s;
  }

  .cardButton:hover {
    transform: scale(1.2);
  }
</style>
