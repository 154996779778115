<template>
  <div
    v-if="isStalkerVisible"
    class="stalker"
    :style="{ left: `${x}px`, top: `${y}px`, width: `${size}px`, height: `${size}px` }"
  />
</template>

<script>
export default {
  data() {
    return {
      x: 0,
      y: 0,
      size: 20,
      isMobileOrTablet: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Touch/i.test(window.navigator.userAgent),
      isStalkerVisible: false, // デフォルト値をfalseに変更
    };
  },

  mounted() {
    if (this.isMobileOrTablet) {
      this.isStalkerVisible = false; // スマホやタブレットの場合、非表示にする
    } else {
      this.isStalkerVisible = true; // それ以外のデバイスでは表示する
      document.addEventListener('mousemove', this.updateMousePosition);
      document.addEventListener('mouseover', this.checkLinkHover);
      document.addEventListener('mouseout', this.hideStalker);
    }
  },

  beforeUnmount() {
    if (!this.isMobileOrTablet) {
      document.removeEventListener('mousemove', this.updateMousePosition);
      document.removeEventListener('mouseover', this.checkLinkHover);
      document.removeEventListener('mouseout', this.hideStalker);
    }
  },

  methods: {
    updateMousePosition(e) {
      this.x = e.clientX;
      this.y = e.clientY;
      if (!this.isStalkerVisible) {
        this.showStalker();
      }
    },

    expandStalker() {
      this.size = 120;
    },

    shrinkStalker() {
      this.size = 20;
    },

    checkLinkHover(event) {
      if (event.target.tagName.toLowerCase() === 'a') {
        this.expandStalker();
      } else {
        this.shrinkStalker();
      }
    },

    hideStalker(event) {
      if (event.relatedTarget === null) {
        this.isStalkerVisible = false;
      }
    },

    showStalker() {
      this.isStalkerVisible = true;
      this.size = 0;
      setTimeout(() => {
        this.size = 20;
      }, 100);
    }
  }
};
</script>

<style>
.stalker {
  position: absolute;
  background-color: white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  mix-blend-mode: difference;
  transition: width 0.3s, height 0.3s;
  z-index: 9999;
}
</style>
