<template>
  <div
    v-if="isLoading"
    class="loading-screen"
    :style="{ height: screenHeight, backgroundColor: backgroundColor }"
  >
    <img
      :src="logo"
      alt="Logo"
      class="logo"
    >
    <transition
      name="fade"
      @after-leave="startHeightAnimation"
    >
      <div
        v-if="displayPercentage"
        class="percentage"
      >
        {{ percentage }}%
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage: 0,
      displayPercentage: true,
      screenHeight: '100vh',
      logo: require('@/assets/logo.svg'), 
      isLoading: true,
      backgroundColor: 'rgba(0, 0, 0, 1)'  // 新しく追加
    };
  },
  created() {
    this.incrementPercentage();
  },
  mounted() {
    if (document.readyState === 'complete') {
      this.isLoading = false; 
    }
  },
  methods: {
    incrementPercentage() {
      if (this.percentage < 100) {
        this.percentage++;
        setTimeout(this.incrementPercentage, 30); 
      } else {
        this.fadeOutPercentage();
      }
    },
    fadeOutPercentage() {
      this.displayPercentage = false;
    },
    startHeightAnimation() {
      this.screenHeight = '80px';
      this.backgroundColor = 'rgba(0, 0, 0, 0)';  // 背景色を変更
    }
  }
}
</script>

<style scoped>
.loading-screen {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  transition: height 1s cubic-bezier(.39,0,.1,1), background-color 1s cubic-bezier(.39,0,.1,1);
  z-index: 9998;
}

.percentage {
  font-size: 0.5rem;  
  font-weight: bold;
  position: absolute;
  top: 50%; 
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  position: absolute;
  top: 45%; 
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px; 
  height: auto;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
